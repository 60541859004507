
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import '../styles/globals.css'
import '../styles/styles.scss'
import Script from 'next/script'
import {GTM_ID, pageView} from '../lib/gtm'
import {useRouter} from 'next/router'
import {useEffect} from 'react'

function MyApp({Component, pageProps}) {

    const router = useRouter()
    useEffect(() => {
        router.events.on('routeChangeComplete', pageView)
        return () => {
            router.events.off('routeChangeComplete', pageView)
        }
    }, [router.events])

    return (<>
            <Component {...pageProps} />
          {/*  <Script*/}
          {/*      id="gtag-base"*/}
          {/*      strategy="afterInteractive"*/}
          {/*      dangerouslySetInnerHTML={{*/}
          {/*          __html: `*/}
          {/*  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':*/}
          {/*  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],*/}
          {/*  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=*/}
          {/*  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);*/}
          {/*  })(window,document,'script','dataLayer', '${GTM_ID}');*/}
          {/*`,*/}
          {/*      }}*/}
          {/*  />*/}
{/*            <Script*/}
{/*                id="chat_script"*/}
{/*                strategy="afterInteractive"*/}
{/*                dangerouslySetInnerHTML={{*/}
{/*                    __html: ` window.__lc = window.__lc || {};*/}
{/*    window.__lc.license = 15528597;*/}
{/*    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))*/}
{/*`,*/}
{/*                }}*/}
{/*            />*/}
        </>
    )
}

const __Page_Next_Translate__ = MyApp


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  